#success-animation {
	width: 100px;
	display: block;
	margin: 30px 0;
}

#success-animation .path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}
#success-animation .path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 0.9s ease-in-out;
}
/* #success-animation .path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 0.9s 0.35s ease-in-out forwards;
} */
#success-animation .path.check {
	stroke-dashoffset: -100;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
