.formWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 3.5rem 2rem;
	margin: 3rem auto;
	max-width: 900px;
}

.formRow {
	display: flex;
}

.formWrapper form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.formWrapper form {
	width: 100%;
}

@media (max-width: 510px) {
	.formWrapper form {
		width: 100%;
	}
	.formWrapper {
		padding: 2.5rem 1.5rem;
	}
}

.formWrapper label {
	color: rgb(216, 216, 216);
	letter-spacing: 2px;
	line-height: 1.75rem;
	font-family: "Myriad-Pro-Regular";
	font-size: 1rem;
}

.inputGroup {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;

	margin: 0.5rem;
}

.inputGroup {
	width: 100%;
}

.inputGroup input {
	height: 2.5rem;
	width: 100%;
	border-radius: 5px;
	border: 2px solid rgb(167, 167, 167);
	background-color: white;
	padding: 0 10px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}

/* .submitBtn {
	width: 100%;

	height: 2.5rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
} */
.submitBtn {
	width: 100%;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
	height: 2.5rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}

.submitBtn:hover,.submitBtn:disabled {
	background-color: rgb(94, 155, 201);
}

.switchToSignUpBtn {
	width: 100%;

	height: 2.5rem;
	background-color: rgba(255, 255, 255, 0);
	color: black;
	border-radius: 5px;
	font-size: 1rem;
	border: 2px solid black;
	cursor: pointer;
	margin-top: 1rem;
}
.forgotPasswordBtn {
	border: none;
	background: transparent;
	letter-spacing: 1px;
	font-size: 0.8rem;
	align-self: flex-end;
	font-family: "Myriad-Pro-Regular";
}
.forgotPasswordBtn:hover {
	text-decoration: underline;
}
.errorMessage {
	color: rgb(208, 41, 41);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
}
.successMessage {
	color: green;
	text-align: center;
	letter-spacing: 1px;
	font-size: 1rem;
	font-family: "Myriad-Pro-Regular";
}

.generalMessage {
	color: rgb(176, 176, 176);
	letter-spacing: 1px;
	font-size: 1rem;
	font-family: "Myriad-Pro-Regular";
	text-align: center;
}

/* Checkbox area */
.userTypeSelection {
	margin: 2rem 0;
}

.userTypeSelectionTitle {
	display: block;
	color: black;
	text-align: center;
	margin: 0 auto 1rem auto;
	letter-spacing: 2px;
	line-height: 1.75rem;
	font-family: "Myriad-Pro-Regular";
}
.userTypeErrorMessage {
	display: block;
	color: red;
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin: 0 auto;
	margin-top: 10px;
	text-align: center;
}
.checkboxGroup {
	display: flex;
}
.container {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 18px;
	cursor: pointer;
	font-size: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: 0 15px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}
/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 2px solid rgb(158, 158, 158);
	border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: rgba(204, 204, 204, 0.405);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	/* background-color: #ccc; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 1px;
	width: 3px;
	height: 8px;
	border: solid rgb(158, 158, 158);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}


.paymentForm {
	width: 100%;
	
}
.subscriptionSelectTitle {
	color: rgb(226, 226, 226);
	margin-bottom: 1.5rem;
	text-align: center;
}

.cardContainer {
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
gap: 1rem;

}
.subscriptionCard {
	background: rgb(25,26,28);
	background: linear-gradient(135deg, #005da4 0%, #002f53 100%); 
width: 100%;
text-align: left;

border: none;
border-right: 1px solid #3077ad;
border-bottom: 1px solid #3077ad;


height: 250px;
border-radius: 5px;
padding: 10px;

box-shadow: 5px 5px 15px -6px #000000;

display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-between;
align-items: flex-start;
transition: transform 300ms ease;	transition: transform 300ms ease, box-shadow 300ms ease;

}
.subscriptionCard:hover {
	background: rgb(25,26,28);
	background: linear-gradient(135deg, #006dc0 0%, #003258 100%); 
	transform: scale(1.05);
	transition: transform 300ms ease, box-shadow 300ms ease;
	box-shadow: 5px 5px 25px -6px #000000;
}
.passName {
	display: block;
	font-size: 1.5rem;
	font-family: "Myriad-Pro-Bold";
}

.passDesc {
	display: block;
font-size: 1rem;
font-family: "Myriad-Pro-Regular";
margin-top: 5px;
color: rgba(255, 255, 255, 0.913);
}
.price {
	margin-top: 1rem;
	display: block;
	font-size: 1rem;
}
.selectBtn {
	width: 100%;

	height: 2rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}
.subscriptionInfoCard {
	width: 100%;

	border: 1px solid grey;
	
	box-shadow: 5px 5px 15px -6px #000000;
border-radius: 5px;
padding: 20px;
background: rgb(25,26,28);
	background: linear-gradient(135deg, hsla(206, 100%, 32%, 0.672) 0%, #005da425 100%); 
display: flex;
flex-direction: row;
justify-content: space-between;
align-items:center;
}

.changeSubBtn {
	width: 120px;

	height: 2rem;
	color: rgb(255, 255, 255);
	border: 1px solid rgb(255, 255, 255);
	background: transparent;

	border-radius: 5px;
	font-size: 1rem;
	
	cursor: pointer;

	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}
@media (max-width: 550px ) {
	.subscriptionInfoCard {
		text-align: center;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: auto;
	}
	.changeSubBtn {
		margin-top: 1rem;
	}
}
.changeSubBtn:hover {
	background: white;
	
	color: rgb(0, 93, 164);
}
.selectBtn:hover{
	background-color: rgb(94, 155, 201);
}
@media (max-width: 550px ) {
	.cardContainer {
		flex-wrap: wrap;
	}
	.subscriptionCard {
		height: 150px;
	}
}
.skipLink {
	text-align: center;
	display: block;
	margin-top: 4rem;
	font-family: "Myriad-Pro-Regular";
	color: rgb(180, 180, 180);
}

.skipLink:hover {

	color: rgb(241, 241, 241);
}



.horizontalRule {
	margin: 25px 0;
	height: 2px;
	width: 100%;
	background-color: rgb(211, 211, 211);
	border: none;
}
.paymentElement {
	width: 100%;
}
.paymentConfirmation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 550px;
}
.paymentConfirmation h3 {
	color: rgb(226, 226, 226);
	margin-bottom: 25px;
	text-align: center;
	font-family: "Erbaum-Regular";
}
.paymentConfirmation .submitBtn {
	width: 300px;
}
@media (max-width: 340px) {
	.paymentConfirmation .submitBtn {
		width: 100%;
	}
}
.paymentConfirmation .infoText {
	margin-top: 45px;
	color: rgb(116, 116, 116);
	text-align: center;
}

.verifyEmailPrompt {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
}

.subscriptionMessage {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
	box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	-webkit-box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	-moz-box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	border-radius: 5px;
	margin-top: 15px;
	padding: 10px;
	background: rgb(230, 244, 255);
}
.subscriptionMessage > span {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Bold";
}

.policyPrompt {
	display: flex;
	align-items: flex-start;
	margin-top: 15px;
}
.policyPrompt input {
	margin-top: 4px;
}
.policyPromptText {
	margin-left: 9px;

	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
}

.policyPrompt a {
	color: rgb(0, 93, 164);
}
