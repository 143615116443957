.row {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
}

.placeholderBlock {
	width: 100%;
	margin-right: 25px;
	margin-bottom: 2rem;
	margin-top: 25px;
}
.placeholderImage {
	width: 100%;
	height: 170px;
	background-color: rgb(110, 110, 110);
}

.placeholderText {
	width: 80%;
	height: 25px;
	margin-top: 10px;
	background-color: rgb(68, 68, 68);
}

@media (max-width: 800px) {
	.row {
		flex-direction: column;
		padding: 1rem;
	}
}
