.contentBlock {
	margin: 25px 15px;
	text-decoration: none;
}
.row {
	display: flex;
	justify-content: center;

	justify-content: space-between;
}

.showDataBtnRow {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	padding: 0 15px;
}

.showMoreBtn,
.showLessBtn {
	margin: 0 10px;
	font-family: "Myriad-Pro-Semibold";
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;

	width: 350px;
	background-color: transparent;
	border: none;
	color: rgb(62, 157, 229);
	text-align: center;
	padding: 10px;
}

.showLessBtn img {
	margin-right: 7px;
}
.showMoreBtn img {
	margin-left: 7px;
}
.showLessBtn img {
	transform: rotate(180deg);
}

.showMoreBtn:hover,
.showLessBtn:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 7px;
	cursor: pointer;
}

.horizontalLines {
	width: 100%;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.25);
}

.slick-slide {
	margin: 0 10px;
}
.contentBlockImg {
	width: 100%;
	padding-top: calc(591.44 / 1127.34 * 100%);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.broadcastInfo {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.moreActions {
	position: relative;
}
.moreActions > button {
	background-color: transparent;
	padding: 0.2rem 0.5rem;
	border: none;
}
.moreActions > button:hover {
	background-color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
}
.moreActions .optionsList {
	position: absolute;
	right: 0;
	top: 35px;
	background-color: rgba(255, 255, 255);
	border-radius: 10px;
	padding: 1rem;
	width: 175px;
}
.moreActions .optionsList ul li {
	padding: 0.5rem 0;
}
.moreActions .optionsList ul li a {
	color: black;
	text-align: center;
}

.broadcastSubheading {
	text-transform: uppercase;
	color: rgb(224, 224, 224);
	line-height: 0.75rem;
	margin-top: 0.25rem;
	font-size: 0.75rem;
	letter-spacing: 2px;
}

.startsAtText {
	text-transform: uppercase;
	color: rgb(224, 224, 224);
	line-height: 0.75rem;
	margin-top: 0.75rem;
	font-size: 0.75rem;
	letter-spacing: 2px;
}

.broadcastDate time,
.broadcastTime,
.broadcastTime time {
	text-transform: uppercase;
	color: rgb(160, 160, 160);
	line-height: 0.75rem;
	/* margin-top: 0.25rem; */
	font-size: 0.75rem;
	letter-spacing: 2px;
}
