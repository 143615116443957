.home {
	display: block;
	width: 100%;
}
.featuredVideo {
	margin: 0 auto;
	max-width: 1200px;
	padding-bottom: 2rem;
}
.videoWrapper {
	position: relative;
	/* background-color: rgba(0, 0, 0, 0.25); */
	overflow: hidden;

	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.videoWrapper video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
.find {
	text-align: center;
	display: block;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 4rem 0.5rem 2rem 0.5rem;
}
.findTitle {
	display: block;

	text-transform: uppercase;

	letter-spacing: 3px;
}
.blueHorizontalRule {
	margin: 0.75rem auto;
	width: 6rem;
	height: 3px;
	background-color: rgb(62, 157, 229);
}

.findDesc {
	/* font-size: 1.25rem; */
	text-align: center;
	padding: 0 2rem;
	margin: 0 auto;
	width: 100%;
	max-width: 500px;
	line-height: 1.25;
	letter-spacing: 1.5px;
}

.findSearchButton {
	width: 100px;
	background: #808080;
	color: #fff;
	border: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.searchBar {
	margin: 1rem 0;
}
.searchBar input {
	height: 40px;
	width: 100%;
	max-width: 500px;
	border-radius: 7px 0 0 7px;
	font-size: 1rem;
	padding: 0 15px;
	border: none;
}
.searchBar button {
	height: 40px;
	width: 100px;
	border-radius: 0 7px 7px 0;
	background-color: rgb(62, 157, 229);
}
.gamesNearMeLink {
	width: 275px;
	font-family: "Myriad-Pro-Semibold";
	letter-spacing: 2px;
	color: white;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 0.5rem 0;
}
.gamesNearMeLink:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 7px;
}

/*started*/
.started {
	display: block;
	width: 100%;
	text-align: center;
	padding: 3rem 0.5rem;
	background-image: url("../../../img/unsplash-eduardo-balderas-UVxd5b-_tw8.png");
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	background-position: center center;
	background-attachment: fixed;
}
.started:before {
	position: absolute;
	z-index: 1;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgb(45, 47, 53);
	background: -moz-linear-gradient(
		180deg,
		rgba(37, 40, 46, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	background: -webkit-linear-gradient(
		180deg,
		rgba(37, 40, 46, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(37, 40, 46, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d2f35",endColorstr="#000000",GradientType=1);
}

.startedTitle {
	display: block;
	margin: 0 0 3rem;
	text-transform: uppercase;

	text-align: center;
	letter-spacing: 3px;
	position: relative;
	z-index: 2;
}
.startedBlockContainer {
	z-index: 2;
	display: flex;
	max-width: 1200px;
	margin: 0 auto 3rem;
	justify-content: center;
	flex-wrap: wrap;
}
.startedBlock {
	display: block;
	width: 335px;
	margin: 0.5rem;
	position: relative;
	padding: 1.5rem;
}
@media (max-width: 750px) {
	.startedBlockContainer {
		flex-direction: column;
		align-items: center;
		margin-bottom: 0;
	}

	.startedBlock {
		width: 100%;
		max-width: 450px;
		margin: 0 0 0.5rem 0;
	}
}
@media (max-width: 430px) {
	.started {
		padding-bottom: 0;
	}
}

.startedBlock::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	top: 0;
	left: 0;
	background: #000;
	opacity: 0.6;
}
.startedBlockIcon {
	position: relative;
	z-index: 1;
	display: block;
	margin: 0 auto 1rem;
}
.startedBlockTitle {
	display: block;
	position: relative;
	z-index: 1;
	text-align: center;
	margin-bottom: 1rem;
color: white;
	font-weight: bolder;
}
.startedBlockDesc {
	display: block;
	position: relative;
	z-index: 1;
	color: rgba(255, 255, 255, 0.897);
	text-align: center;
	letter-spacing: 1px;
	/* font-size: 1rem; */
	font-weight: 300;
	line-height: 1.5;
}
.startedSignUp {
	position: relative;
	font-family: "Erbaum-Book", sans-serif;
	z-index: 2;
	display: inline-block;
	text-decoration: none;
	background: #ffda1a;
	color: #000;
	font-size: 1.25rem;
	padding: 0.5rem 2rem;
	border-radius: 3px;
	border: none;
	margin-bottom: 2rem;
}
.startedSignUp:hover {
	background: #d7b817;
}
@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
	.started {
		padding: 10rem 0.5rem 7rem 0.5rem;
	}
}

/*about*/
.about {
	display: block;
	width: 100%;
	margin: 0 auto;
	background-color: #fff;
	background-image: url("../../../img/Noise-Image.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #000;
	padding: 5rem 0.5rem;
}
.aboutContainer {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
.aboutTitle {
	display: block;
	text-align: center;

	color: #000;
	margin: 1rem;
	/* font-family: "Erbaum-Light"; */
	text-transform: none;
}
.aboutTitle span {
	color: rgb(136, 136, 136);
	font-size: 1rem;
	display: block;
	/* margin-bottom: 0.25rem; */
}
.aboutTitle::after {
	content: "";
	display: block;
	width: 100px;
	height: 4px;
	background: #1da1f2;
	margin: 2rem auto 0 auto;
}
.aboutDesc {
	display: block;
	width: 100%;

	margin: 0 auto;
	color: #000;
	line-height: 2rem;
	text-align: center;
	/* font-size: 1.25rem; */
	letter-spacing: 1.5px;
}

.gradientBackground {
	background: rgb(59, 63, 71);
	background: -moz-radial-gradient(
		circle,
		rgba(59, 63, 71, 1) 0%,
		rgba(37, 40, 46, 1) 77%
	);
	background: -webkit-radial-gradient(
		circle,
		rgba(59, 63, 71, 1) 0%,
		rgba(37, 40, 46, 1) 77%
	);
	background: radial-gradient(
		circle,
		rgba(59, 63, 71, 1) 0%,
		rgba(37, 40, 46, 1) 77%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b3f47",endColorstr="#25282e",GradientType=1);
}
/*ways*/
.ways {
	display: block;
	width: 100%;
	text-align: center;
	padding: 7rem 0.5rem;
}
.waysTitle {
	display: block;
	margin: 0 0 3rem;
	text-transform: uppercase;

	text-align: center;
}
.waysBlockContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: 0 auto 1rem;
	width: 100%;
	max-width: 1200px;
	align-items: center;
}
.waysBlock {
	display: block;
	width: 100%;
	max-width: 250px;
	padding: 1rem;
	margin-bottom: 2em;
}
.waysBlockImg {
	display: block;
	height: 6rem;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 1rem;
}
.waysBlockTitle {
	display: block;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-family: "Erbaum-Light", sans-serif;
	font-size: 1.5rem;
	color: #fff;
	margin: 1.35rem 0;
	font-weight: 600;
}
.waysBlockDesc {
	display: block;
	color: #fff;
	font-weight: 300;
	letter-spacing: 1px;
	line-height: 1.5;
	text-align: center;
}
.waysImg {
	display: block;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
}
/* Get The App */

.getTheApp {
	display: flex;
	max-width: 550px;
	margin: 1rem auto;
	width: auto;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
	border-top: 1px solid #d8d8d8;
	border-bottom: 1px solid #d8d8d8;
}
.getTheApp h4 {
	margin-right: 4rem;
	font-family: "Erbaum-Book";
	text-align: left;
}
.storeDownloads {
	display: flex;
}

.storeDownloads img:nth-child(1) {
	margin-right: 2rem;
}
@media screen and (max-width: 500px) {
	.getTheApp {
		flex-direction: column;
	}
	.getTheApp h4 {
		margin-right: 0;
		margin-bottom: 1rem;
	}
}
@media screen and (min-width: 550px) {
	.waysBlockContainer {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}
	.waysBlock {
		max-width: 50%;
	}
}
@media screen and (min-width: 1024px) {
	.waysBlockContainer {
		flex-wrap: nowrap;
		align-items: flex-start;
	}
	.waysBlock {
		max-width: 250px;
	}
}

/*faq*/
.faq {
	display: block;
	width: 100%;
	padding: 7rem 0.5rem;

	max-width: 1200px;
	margin: 0 auto;
}
.faqTitle {
	display: block;
	margin: 0 0 3rem;
	text-transform: uppercase;

	text-align: center;
	letter-spacing: 3px;
}

.faqBlock {
	width: 100%;
	display: block;
	margin-bottom: 1rem;
	background-color: transparent;
	border: none;
}

.faqBlockArrow {
	height: 18px;
	transition: all 200ms;
}
.faqQuestion {
	padding: 1rem;
	background-color: #fff;
	border-radius: 2px 2px 0 0;
	display: flex;
	justify-content: space-between;
	text-align: left;
}
.faqQuestion p {
	color: rgb(32, 32, 32);
	margin-right: 1rem;
}
.faqAnswer {
	border: 1px solid white;

	overflow-y: auto;
	padding: 0 2rem;
	transition: all 200ms;
	border-radius: 0 0 2px 2px;
	text-align: left;
}
.faqAnswer p {
	color: rgb(231, 231, 231);
}

/* Pricing */

.pricingToggle {
	margin-top: 1rem;
	background-color: transparent;
	text-transform: uppercase;
	text-decoration: underline;
	border: none;
	position: relative;
	color: #fff;
	font-size: 0.85rem;
	font-family: "Erbaum-Book";
	z-index: 9;
}

.pricingRow {
	display: flex;
	justify-content: space-between;
	margin: 0.5rem 0;
	font-size: 0.85rem;
	font-family: "Erbaum-Book";
}
.pricingContainer {
	overflow-y: hidden;
	transition: all 200ms;
	max-width: 300px;

	margin: 2rem auto;
}
.pricingContainer h3 {
	font-family: "Erbaum-Book";
	font-size: 1.25rem;
}
