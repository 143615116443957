.broadcast {
  margin: 0 auto;
  max-width: 1200px;

  margin-bottom: 6rem;
}
@media (min-width: 1200px) {
  .broadcast {
    padding-top: 2rem;
  }
}
.broadcastVideo {
  width: 100%;
  /* max-width: 1170px; */
  padding: 0 15px;
}
@media (max-width: 800px) {
  .broadcastVideo {
    padding: 0;
  }
}
.videoLoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.videoFrame {
  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.videoFrame .playerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.healthAlert {
  padding: 0.25rem 0.75rem;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  text-align: center;
  background-color: rgba(250, 0, 0, 0.501);
}
.errorScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /* background-image: url("https://c.tenor.com/wi2mP09DeWYAAAAC/methed-up-static.gif"); */
}
.errorScreen .overlay {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgb(0, 0, 0);

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.countDownScreen {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgb(27, 59, 84);

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loadingScreen {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgb(13, 15, 17);

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.broadcastInfo {
  color: white;
  border-bottom: 1px solid rgb(90, 90, 90);
  padding: 1rem 0;
}
/* @media (max-width: 290px) {
	.broadcastInfoBottom {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
} */

.broadcastInfoBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}
/* @media (max-width: 290px) {
	.broadcastInfoBottom {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
} */
.gameTitle {
  display: block;
  font-size: 1.25rem;
  font-family: "Erbaum-Book", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.locationTitle {
  font-size: 0.75rem;
  margin-top: 1rem;
  font-family: "Erbaum-Book", sans-serif;
  letter-spacing: 1px;
  color: rgb(199, 199, 199);
  text-transform: uppercase;
}

.date time {
  font-size: 0.75rem;
  margin-top: 1rem;
  font-family: "Erbaum-Book", sans-serif;
  letter-spacing: 1px;
  color: rgb(199, 199, 199);
  text-transform: uppercase;
}

.broadcastTime {
  display: flex;
}
@media (max-width: 650px) {
  .date span {
    line-height: 1rem;
  }
  .date time {
    font-size: 0.6rem;
    /* margin: 0;
		line-height: 0; */
  }
}
.bottomContent {
  padding: 0 15px;
}

@media (min-width: 875px) {
  .gameTitle3 {
    max-width: 50%;
  }
}

@media (max-width: 875px) {
  .broadcastInfo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .broadcastInfo .buttons {
    margin-top: 1rem;
  }
}

.broadcastNav {
  display: none;

  border-bottom: 1px solid rgb(90, 90, 90);
}
@media (min-width: 1220px) {
  .broadcastNav {
    padding: 0;
  }
}

.broadcastNavMobile {
  border-bottom: 1px solid rgb(90, 90, 90);
  /* padding: 0 1rem; */
}
.broadcastNavMobile select {
  width: 100%;
  height: 35px;
  padding: 0;
  margin-left: -2px;
  font-size: 16px;
  color: rgb(199, 199, 199);
  letter-spacing: 1px;
  border: none;
  font-family: "Erbaum-Book", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../../img/down-arrow.svg") 96% / 25% no-repeat;
  background-size: 10px;
}
.broadcastNavMobile select option {
  color: black;
}
@media (min-width: 640px) {
  .broadcastNav {
    display: block;
  }
  .broadcastNavMobile {
    display: none;
  }
}
.broadcastNav ul li {
  display: inline-block;
  color: rgb(220, 220, 220);
  padding: 2rem 0 0.5rem 0;
  margin-right: 35px;
  font-size: 0.95rem;
  letter-spacing: 2px;
  font-family: "Erbaum-Book", sans-serif;
}
@media (max-width: 560px) {
  .broadcastNav ul li {
    font-size: 0.85rem;
  }
}

.broadcastNav ul li:hover {
  cursor: pointer;
  color: rgb(240, 240, 240);
}
.broadcastNav ul li.active {
  border-bottom: 5px solid rgb(0, 110, 194);
  color: rgb(240, 240, 240);
}

.liveGameSuggestionsRow {
  display: flex;
  margin: 0 -1rem;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 4rem;
}
.liveGameSuggestion {
  margin: 1rem;
  width: 260px;
}
.headerButtons {
  display: flex;
}

/* @media (max-width: 875px) {
	.headerButtons {
		margin-top: 2rem;
	}
} */
@media (max-width: 290px) {
  .headerButtons {
    margin-top: 0;
    align-self: flex-end;
  }
}
.headerButtons button {
  border: none;
  background-color: transparent;
  color: white;
  letter-spacing: 1px;
  font-size: 0.85rem;
  letter-spacing: 1.5px;
  color: rgb(220, 220, 220);
  font-family: "Erbaum-Book", sans-serif;
}
.headerButtons button:hover {
  color: #fff;
}

.headerButtons button img {
  margin-right: 0.5rem;
}

.broadcastSubheading {
  text-transform: uppercase;
  color: white;
}

@media (max-width: 850px) {
  .liveGameSuggestionsRow {
    flex-direction: column;
  }
  .liveGameSuggestion {
    width: 100%;
    display: flex;
  }

  .liveGameSuggestionsRow .liveGameSuggestion img {
    width: 50%;
  }
  .liveGameSuggestionsRow .liveGameSuggestion .info {
    width: 50%;
    padding-left: 1rem;
  }
}

.videoOverlayContainer {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
}

.videoOverlayBtn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.598);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-bottom: 8px;
  width: 36px;
}

