.facility {
	padding-bottom: 5rem;
}
.facilityImg {
	height: 450px;

	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.facilityHeader {
	padding-top: 2rem;
	text-align: center;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
.facilityHeader h1 {
	font-size: 1.85rem;
}
.contentSection {
	max-width: 1200px;
	margin: 4rem auto;
	min-height: 100px;
}
.headingRow {
	display: flex;
	align-items: center;
	margin-left: 15px;
}
.icon {
	display: inline-block;

	margin-right: 10px;
	margin-left: 1rem;
}
.icon img {
	height: 25px;
	width: 25px;
}

.noDataContainer {
	display: flex;
	height: 50px;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem 15px 0 15px;
}
.noDataContainer p {
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
}
.expandableList {
	margin-top: 5rem;
}
