.card {
	width: 700px;
}
.card a {
	text-decoration: none;
}
.card a:hover {
	text-decoration: none;
}
.card img {
	width: 100%;
}

.card h4 {
	font-size: 1.25rem;
	margin-top: 1rem;
	letter-spacing: 1px;
}

.arrow {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.15);
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.arrow:hover {
	background-color: rgba(255, 255, 255, 0.35);
}
.arrow img {
	height: 20px;
}
