.sidebar {
	width: 20%;
	padding: 1rem;
	margin-right: 2rem;

	border-radius: 7px;
}

.filterHeader {
	font-family: "Myriad-Pro-Semibold";
	letter-spacing: 1px;
	margin-bottom: 1.5rem;
	font-size: 1.2rem;
}
.sportFilter {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}
.sportFilter label {
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 1rem;
}

.dateFilter {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}
.datePicker {
	display: flex;
	align-items: center;
}
.datePickerRow {
	display: flex;
	align-items: center;
	margin: 5px 0;
	width: 100%;
}
.datePickerRow > button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	width: 35px;
	border: none;
	border-radius: 0 7px 7px 0;
	margin: 0;
	background-color: rgb(202, 202, 202);
}
.datePickerRow > button > img {
	width: 15px;
}
.arrowWrapper {
	display: flex;
	align-items: center;
	width: 25px;
	height: 35px;
	border-radius: 0 7px 7px 0;
	background-color: rgb(202, 202, 202);
}

.dateInputLabel {
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 0.75rem;
}
.dateFilter input {
	height: 35px;
	border-radius: 7px 0 0 7px;
	border: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 1rem;
	background-color: rgb(202, 202, 202);
}
.datePickerRow:nth-of-type(1) {
	margin-bottom: 1rem;
}

.dateFilter input:after {
	content: url("../../../../img/grey-right-arrow.svg");
}
.searchFilter form {
	display: "flex";
}

.searchFilter input {
	height: 35px;
	width: 100%;
	border-radius: 7px;
	border: none;
	padding: 0 7px;
	margin-top: 0.5rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 1rem;
	background-color: rgb(202, 202, 202);
}

@media (max-width: 900px) {
	.sportFilter label,
	.dateInputLabel,
	.filterHeader {
		color: black;
	}
}

/* Checkbox styles */
/* Customize the label (the container) */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 18px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 2px solid rgb(204, 204, 204);
	border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: rgba(204, 204, 204, 0.294);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 1px;
	width: 3px;
	height: 8px;
	border: solid rgb(204, 204, 204);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

@media (max-width: 900px) {
	.checkmark {
		border: 2px solid rgb(75, 75, 75);
	}
	.container .checkmark:after {
		left: 5px;
		top: 1px;
		width: 3px;
		height: 8px;
		border: solid rgb(75, 75, 75);
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.mobileFilterMenu {
	position: absolute;
	width: 100%;
	margin-top: 100px;
	top: 0;
	z-index: 10;
}

.applyBtn {
	display: block;
	padding: 0 50px;
	height: 35px;
	width: 100%;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	border-radius: 7px;
	border: none;
	margin-top: 3rem;
	font-size: 1rem;
	color: white;
	background-color: rgb(62, 157, 229);
}
.applyBtn:hover {
	background-color: rgb(45, 115, 169);
}
.applyBtn:disabled {
	opacity: 0.35;
	background-color: rgb(45, 115, 169);
}
.applyBtn:disabled:hover {
	opacity: 0.35;
	background-color: rgb(45, 115, 169);
	cursor: default;
}

@media (max-width: 900px) {
	.sidebar {
		width: 100%;
		background-color: #eeeeee;
		padding: 4rem 1rem;
	}

	.applyBtn {
		display: block;
	}
}
