.socialbtn {
	margin-right: 1.5rem;
}
.share {
	width: 350px;
	background-color: #fff;
	padding: 1rem;
	padding-top: 3rem;
}
.row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.copyLinkRow {
	margin-top: 1rem;
	display: flex;
	border: 1px solid rgb(61, 61, 61);
	padding: 5px;
	align-items: center;
	justify-content: space-between;
}
.copyLinkTxt {
	color: #000;
	font-size: 12px;
	border: none;
	width: 100%;
	padding: 0 5px;
}
.copyLinkBtn {
	background-color: transparent;
	border: none;
	font-size: 12px;
	font-family: "Erbaum-Book", sans-serif;
}

@media (max-width: 380px) {
	.share {
		width: 100%;
	}
	.copyLinkRow {
		flex-direction: column;
		align-items: flex-start;
		border: none;
		padding: 0;
	}
	.copyLinkTxt {
		border: 1px solid rgb(61, 61, 61);
		padding: 5px;
	}
	.copyLinkBtn {
		margin-top: 10px;
		border: 1px solid rgb(61, 61, 61);
		padding: 5px;
	}
}
