.mobileNav {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	background: rgb(31, 31, 31);
	background: -moz-linear-gradient(
		0deg,
		rgba(31, 31, 31, 1) 0%,
		rgba(11, 11, 11, 1) 100%
	);
	background: -webkit-linear-gradient(
		0deg,
		rgba(31, 31, 31, 1) 0%,
		rgba(11, 11, 11, 1) 100%
	);
	background: linear-gradient(
		0deg,
		rgba(31, 31, 31, 1) 0%,
		rgba(11, 11, 11, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f1f1f",endColorstr="#0b0b0b",GradientType=1);
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	z-index: 12;
	border-top: 1px solid #4b4b4b;
}

@media (min-width: 1024px) {
	.mobileNav {
		display: none;
	}
}

.navItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-decoration: none;
	cursor: pointer;
}
/* .navItemActive {
	background-color: red;
} */
.navItemActive .navItemText {
	color: white;
}
.navItem svg {
	height: 20px;
	margin: 0 auto;
	margin-bottom: 0.25rem;
}
.navItem img {
	margin: 0 auto;
	margin-bottom: 0.5rem;
	width: 20px;
	height: 20px;
}
.navItemActive svg path {
	fill: #005da4;
}

.navItemText {
	text-align: center;
	font-size: 10px;
	color: grey;
	letter-spacing: 1px;
	font-family: "Erbaum-Book";
}
