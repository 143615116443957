.privacyPolicy {
	margin: 4rem auto;
	max-width: 1200px;
padding: 0 15px;
}
.privacyPolicy h4,.privacyPolicy h2 {
    margin: 2rem 0 1rem 0;
}
.privacyPolicy p {
    margin-bottom: 1rem;
}