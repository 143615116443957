.accessPrompt {
	height: 100%;
	width: 100%;
	display: flex;

	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: rgb(27, 59, 84);
	background-size: cover;
}

.accessPrompt button {
	width: 200px;
	height: 2.5rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	margin-bottom: 10px;
}

.accessPrompt a {
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 2.5rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	margin-bottom: 10px;
}
.accessPrompt a:hover {
	text-decoration: none;
}
