.mobileMediaList {
	margin-top: 2rem;
}

.mobileMediaList .slick-slide {
	padding: 0 10px;
}
.mobileMediaList .slick-slide {
	padding: 0 10px;
}
.itemTitle {
	margin-top: 1rem;
}

.itemLocation {
	text-transform: uppercase;
	color: rgb(224, 224, 224);
	font-size: 0.75rem;
	letter-spacing: 2px;
}
