.error {
	width: 350px;
	background-color: #fff;
	padding: 3rem 1rem;
	padding-bottom: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: black;
}
.errorTitle {
	color: white;
	font-size: 1rem;
	width: 100%;
	background-color: rgb(214, 64, 64);
	margin-bottom: 1rem;
	text-align: left;
	position: absolute;
	padding: 15px;
	top: 0;
	left: 0;
}
.errorDesc {
	color: black;
	text-align: center;
}
.errorContactMessage {
	color: black;
	text-align: left;
	margin-top: 1rem;
}
.errorContactMessage a {
	color: rgb(0, 94, 164);
}
.okBtn {
	border: none;
	margin-top: 2rem;
	padding: 10px 1rem;
	width: 100px;
	background-color: rgb(0, 94, 164);
	color: white;
}

.okBtn:hover {
	background-color: rgba(0, 93, 164, 0.521);
}
