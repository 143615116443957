.moreActions {
	position: relative;
	margin-left: 15px;
}
.moreActions > button {
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;
	border-radius: 18px;
	border: none;
	outline: none;
}
.moreActions > button:hover {
	background-color: rgba(0, 0, 0, 0.25);

	cursor: pointer;
}

.moreActions .icon {
	margin: 0;
}
.moreActions .optionsList {
	position: absolute;
	right: 0;
	top: 40px;
	background-color: rgb(55, 57, 61);
	border-radius: 5px;
	z-index: 10;
	width: 175px;
	-webkit-box-shadow: -2px 5px 6px 0px rgba(0, 0, 0, 0.2);
	box-shadow: -2px 5px 6px 0px rgba(0, 0, 0, 0.2);
}
.moreActions .optionsList ul li {
	/* padding: 0.75rem; */
	width: 175px;

	border-radius: 5px;
}
.moreActions .optionsList ul li:hover {
	background-color: rgb(90, 92, 95);
}
.moreActions .optionsList ul li a {
	width: 175px;
	display: block;
	line-height: 20px;
	letter-spacing: 1px;
	font-size: 14px;
	font-family: "Myriad-Pro-Regular";
	padding: 0.75rem;
	outline: none;
	color: white;

	text-decoration: none;
}
