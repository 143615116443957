* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: rgb(55,59,68);
	background: radial-gradient(circle, rgb(58, 61, 69) 0%, rgb(35, 38, 45) 100%);
	font-family: sans-serif;
}

main.blur {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
	transition: filter 300ms ease;
	
}
main {
	min-height: 70vh;
}


h1 {
	font-size: 2rem;
	color: white;
	letter-spacing: 1px;
	text-transform: uppercase;
}
h2 {
	font-size: 2rem;
	color: white;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}
h3 {
	font-size: 1.5rem;
	color: white;
	text-transform: uppercase;
}
h4 {
	font-size: 1.35rem;
	color: white;
	text-transform: uppercase;
}
h5 {
	font-size: 1.5rem;
	color: white;
	text-transform: uppercase;
}
h6 {
	font-size: 1rem;
	color: white;
	text-transform: uppercase;
	line-height: 1.15rem;
}
button {
	font-size: 1rem;
	cursor: pointer;
}
label,
p {
	font-size: 1rem;
	line-height: 1.25rem;
	color: white;
}

@media (max-width: 650px) {
	h1 {
		font-size: 1.75rem;
		line-height: 2.1rem;
	}
	h3 {
		font-size: 1rem;
		line-height: 1.3rem;
	}
	p {
		font-size: 0.95rem;
		line-height: 1.5rem;
		color: white;
	}
}
section {
	width: 100%;
	margin: 0 auto;
}

section.container {
	max-width: 1200px;
}
@font-face {
	font-family: "Myriad-Pro-Bold";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-Bold.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-Bold.woff") format("woff");
}
@font-face {
	font-family: "Myriad-Pro-Bold-lt";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-BoldIt.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-BoldIt.woff") format("woff");
}
@font-face {
	font-family: "Myriad-Pro-Light";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-Light.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-Light.woff") format("woff");
}
@font-face {
	font-family: "Myriad-Pro-Regular";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-Regular.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-Regular.woff") format("woff");
}
@font-face {
	font-family: "Myriad-Pro-Semibold";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-Semibold.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-Semibold.woff") format("woff");
}
@font-face {
	font-family: "Myriad-Pro-Semiboldlt";
	src: url("./fonts/Myriad-Pro-woff/MyriadPro-SemiboldIt.woff2") format("woff2"),
		url("./fonts/Myriad-Pro-woff/MyriadPro-SemiboldIt.woff") format("woff");
}

@font-face {
	font-family: "Erbaum-Regular";
	src: url("./fonts/Erbaum/Erbaum-Medium.otf") format("opentype");
}
@font-face {
	font-family: "Erbaum-Book";
	src: url("./fonts/Erbaum/Erbaum-Book.otf") format("opentype");
}
@font-face {
	font-family: "Erbaum-Light";
	src: url("./fonts/Erbaum/Erbaum-Light.otf") format("opentype");
}
h1,
h2,
h3 {
	font-family: "Erbaum-Book", sans-serif;
	font-weight: 300;
	font-style: normal;
}

h4,
h5,
h6,
p {
	font-family: "Myriad-Pro-Regular", sans-serif;

	font-style: normal;
}

/* Style overrides for react-spring3d-carousel */
.css-doq0dk {
	margin: 0 auto !important;
	width: 30% !important;
}
.css-1qzevvg img {
	width: 15px;
}
.css-1fzpoyk img {
	object-fit: cover !important;
}
