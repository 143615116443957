.header {
	width: 100%;
	/* height: 70px; */
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 5px 1rem;
	position: relative;
	z-index: 10;
	
}
.header.blur {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
}

.headerLeft {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
}
.headerLogo {
	display: block;
	width: 100%;
	max-width: 145px;
	min-width: 145px;
	padding: 0.1rem 0;
	padding-bottom: 0;
}

.headerRight {
	display: none;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}
.loggedOutHeaderSearch {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	align-items: center;
	cursor: pointer;
	padding: 4px;
	border-radius: 22px;
	background-color: transparent;
	border: none;
}
.loggedOutHeaderSearch:hover {
	background-color: rgba(255, 255, 255, 0.1);
}
.loggedOutHeaderSearch form input[type="text"] {
	background: #686a6e;
	width: 100%;
	height: 36px;
	transition: all 300ms ease-in-out;
	border-radius: 0 18px 18px 0;
	border: none;
	padding-left: 38px;
	padding-right: 18px;
	color: white;
	outline: none;
	letter-spacing: 1px;
}
.loggedOutHeaderSearch form button[type="submit"] {
	height: 36px;
	background-color: transparent;
	border: none;
	color: white;
	padding: 0 10px;
	outline: none;
	letter-spacing: 1px;
}

.loggedOutHeaderSearch input::placeholder {
	color: rgb(212, 212, 212);
}

.loggedOutHeaderSearch form {
	overflow: hidden;
}
.loggedOutHeaderSearch .headerSearchIcon {
	background: #686a6e;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	margin-right: -18px;
	z-index: 1;
}

.loggedOutHeaderSearch .headerSearchIcon img {
	width: 18px;
	height: 18px;
}

/* .loggedOutHeaderSearch .headerSearchText {
	display: block;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #fff;
	font-family: "Erbaum-Book", sans-serif;
	font-size: 1rem;
	margin-left: 0.5rem;
} */
.loggedInHeaderSearch {
	display: block;
	margin: 0 3rem;
	width: 30%;
	min-width: 335px;
}
.loggedInHeaderSearch input {
	height: 30px;
	width: 100%;
	border-radius: 5px 0 0 5px;
	padding: 0 15px;
	border: none;
}
.loggedInHeaderSearch button {
	height: 30px;
	width: 55px;
	border-radius: 0 5px 5px 0;
	background-color: rgb(185, 185, 185);
}

.gamesNearbyLink {
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-right: 2rem;
}

@media (max-width: 1200px) {
	.gamesNearbyLink {
		display: none;
	}
}

.gamesNearbyLink:hover {
	text-decoration: underline;
}
.gamesNearbyLink .nearbyText {
	font-family: "Myriad-Pro-Semibold", sans-serif;
	margin-left: 5px;
	letter-spacing: 1px;
}
.headerLogButtons {
	display: flex;
}
.headerSignOut {
	display: block;
	padding: 0.5rem 2rem;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 2px;
	color: #fff;
	font-family: "Erbaum-Book", sans-serif;
	background: #1b1c21;
	border: 1px solid #fff;
}
.headerSignOut:hover {
	color: #1b1c21;
	background: #fff;
}
.headerSignup {
	display: block;
	padding: 0.5rem 2rem;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #fff;
	font-family: "Erbaum-Book", sans-serif;
	background: #1b1c21;
	border: 1px solid #fff;
	text-decoration: none;
}

.headerSignup:hover {
	color: #1b1c21;
	background: #ffda1a;
	border: 1px solid #ffda1a;
}
.headerSignin {
	display: block;
	padding: 0.5rem 2rem;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #1b1c21;
	font-family: "Erbaum-Regular", sans-serif;
	background: #fff;
	border: 1px solid #fff;
	text-decoration: none;
}

.headerSignin:hover {
	color: #fff;
	background: #1b1c21;
}
.headerSignin:hover {
	color: #fff;
	background: #1b1c21;
}
.userProfileLink {
	font-family: "Myriad-Pro-Semibold", sans-serif;
	display: flex;
	align-items: center;
	text-decoration: none;
	margin: 0 3rem;
	background: transparent;
	border: none;
	position: relative;
	padding: 0.5rem;
	height: 50px;
}
.userProfileLink .accountActions {
	display: none;
	position: absolute;
	top: 50px;
	right: 0;
	background-color: white;
	/* border-radius: 8px; */
	width: 150px;
	padding: 0.5rem 0;
}
.userProfileLink .accountActions button {
	background-color: transparent;
	width: 100%;
	border: none;
	display: block;
	text-decoration: none;
	padding: 0.25rem 0;
	display: block;
	text-align: left;
	color: #000;
	padding: 0.5rem 1rem;
}
.userProfileLink .accountActions button:hover {
	background-color: rgb(223, 223, 223);
}
.userProfileLink:hover {
	background-color: rgba(0, 0, 0, 0.171);
	/* border-radius: 8px; */
}
.userProfileLink:hover span {
}
.userProfileLink:hover .accountActions {
	width: 100%;
	display: block;
}
.userProfileLink:focus .accountActions {
	width: 100%;
	display: block;
}
.userProfileLink span {
	font-size: 1rem;
}
.userProfileLink .avatar {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: rgb(185, 185, 185);
	margin-right: 0.5rem;
}

.sideDrawerToggler {
	position: absolute;
	left: 0;
}

@media screen and (min-width: 1024px) {
	.header {
		padding: 0.25rem 1rem;
	}

	.headerLogo {
		max-width: 200px;
	}

	.headerLeft {
		justify-content: flex-start;
		width: auto;
	}
	.headerRight {
		display: flex;
	}

	.loggedOutHeaderSearch {
		margin: 0 3rem 0 0;
	}

	.sideDrawerToggler {
		display: block;
		position: static;
	}
}
