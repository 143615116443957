.pageNotFound {
	padding: 4rem 1rem;
	margin-bottom: 5rem;
}
.heading {
	text-align: center;
}
.subHeading {
	text-align: center;
}
.find {
	text-align: center;
	display: block;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 7rem 0.5rem;
}
@media (max-width: 1024px) {
	.find {
		margin-bottom: 5rem;
	}
}
.findTitle {
	display: block;

	text-transform: uppercase;

	letter-spacing: 3px;
}
.blueHorizontalRule {
	margin: 1rem auto;
	width: 6rem;
	height: 3px;
	background-color: rgb(62, 157, 229);
}

.findDesc {
	font-size: 1.25rem;
	text-align: center;
	padding: 0 2rem;
	margin: 0 auto;
	width: 100%;
	max-width: 500px;
	line-height: 1.25;
	letter-spacing: 1.5px;
}

.findSearchButton {
	width: 100px;
	background: #808080;
	color: #fff;
	border: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.searchBar {
	margin: 1rem 0;
}
.searchBar input {
	height: 40px;
	width: 100%;
	max-width: 500px;
	border-radius: 7px 0 0 7px;
	font-size: 1rem;
	padding: 0 15px;
	letter-spacing: 1px;
	border: none;
}
.searchBar button {
	height: 40px;
	width: 100px;
	border-radius: 0 7px 7px 0;
	background-color: rgb(62, 157, 229);
}
.gamesNearMeLink {
	width: 275px;
	font-family: "Myriad-Pro-Semibold";

	color: white;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 0.5rem 0;
	letter-spacing: 2px;
}
.gamesNearMeLink:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 7px;
}
