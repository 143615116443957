.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.placeholderBlock {
	width: 100%;
	/* margin-right: 15px;
	margin-bottom: 2rem;
	margin-top: 2rem; */
}
.placeholderTitle {
	width: 80%;
	max-width: 600px;
	height: 30px;
	background-color: rgb(110, 110, 110);
}

.placeholderDate1 {
	width: 125px;
	height: 15px;
	margin-top: 20px;
	background-color: rgb(68, 68, 68);
}
.placeholderDate2 {
	width: 175px;
	height: 15px;
	margin-top: 10px;
	background-color: rgb(68, 68, 68);
}
.actions {
	width: 175px;
	height: 15px;
	margin-top: 10px;
	background-color: rgb(68, 68, 68);
}

@media (max-width: 800px) {
	.actions {
		width: 75px;
		height: 15px;
		margin-top: 10px;
		background-color: rgb(68, 68, 68);
	}
}
