.sideDrawer {
	display: flex;
	flex-direction: column;

	overflow: hidden;
	position: fixed;
	top: -100vh;
	left: 0;
	height: 100%;
	z-index: 11;
	width: 100%;
	background-color: rgb(0, 94, 164);
	/* box-shadow: -5px 0px 20px 5px rgba(0, 0, 0, 1); */
	transition: top 300ms ease;
}
.sideDrawerToggler {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
	height: 45px;
	width: 45px;
	border-radius: 50%;
}
.sideDrawerToggler:hover {
	background-color: rgba(129, 129, 129, 0.295);
}

.sideDrawer.open {
	top: 0;
	transition: top 300ms ease;
}
.nav {
	padding-top: 3rem;
}

.sideDrawerHead {
	display: flex;
	position: relative;
	align-items: center;
	height: 75px;
	justify-content: center;
	padding: 0 25px;
}

.navItem {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 1rem 3.5rem;
	cursor: pointer;
	font-size: 1.25rem;
	background-color: transparent;
	font-family: "Myriad-Pro-Regular", sans-serif;
	text-decoration: none;
	border: none;
	color: white;
}
.additionalLinks li a {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 1rem 3.5rem;
	cursor: pointer;
	font-size: 1.25rem;
	background-color: transparent;
	font-family: "Myriad-Pro-Regular", sans-serif;
	text-decoration: none;
}
.additionalLinks li a:hover {
	background-color: rgb(0, 116, 206) !important;
}
.navItem:hover {
	background-color: rgb(0, 116, 206) !important;
}
.navItem svg {
	transition: transform 200ms ease-in-out;
	pointer-events: none;
}

.subMenu {
	transition: all 200ms ease-in-out;
	overflow: hidden;
	background-color: rgb(0, 74, 131);
	padding: 0;
}

.subMenu > li > a {
	display: block;
	text-decoration: none;
	font-size: 1rem;
	height: 100%;
	width: 100%;
	padding: 1rem 5rem;
	font-family: "Myriad-Pro-Regular", sans-serif;
}
.subMenu > li:hover {
	background-color: rgb(0, 56, 98);
}

.mobileMenuCloseBtn {
	border: none;
	background-color: transparent;
	position: absolute;
	right: 2rem;
	top: 2rem;
}

.horizontalRule {
	width: 100%;
	height: 1px;
	margin: 2rem auto;
	max-width: 90%;
	background-color: white;
}

@media (min-width: 1024px) {
	.sideDrawer {
		top: 0;
		left: -300px;
		width: 300px;
		background-color: white;
		transition: left 300ms ease;
	}

	.sideDrawer.open {
		top: 0;
		left: 0;
		transition: left 300ms ease;
	}
	.sideDrawerHead {
		justify-content: flex-start;
		border-bottom: 3px solid grey;
	}
	.navItem {
		color: #000;
	}
	.navItem svg path {
		stroke: rgb(0, 56, 98);
	}
	.additionalLinks li a {
		color: #000;
	}
	.horizontalRule {
		background-color: #000;
	}
	.subMenu {
		background-color: rgb(224, 224, 224);
	}
	.subMenu > li a {
		color: #000;
	}
	.subMenu > li:hover {
		background-color: rgb(185, 185, 185) !important;
	}
	.navItem:hover {
		background-color: rgb(185, 185, 185) !important;
	}
}

.versionText {
	color: rgba(0, 0, 0, 0.546);
	display: block;
	margin: 0;
	text-align: left;
	font-size: 0.85rem;
	white-space: nowrap;
	position:absolute;
	bottom: 15px;
	right: 15px;
}