.profile {
	width: 600px;
	padding: 4rem 2rem;
	margin: 0 auto;
}
@media (max-width: 660px) {
	.profile {
		width: 100%;
		padding: 2rem 2rem;
		margin: 0 auto;
	}
}
.profile h2 {
	color: black;
	text-align: center;
}
.accountItem {
	padding: 1.75rem 0;
}
.accountItem:nth-of-type(2) {
	border-top: 2px solid rgb(191, 191, 191);
	border-bottom: 2px solid rgb(191, 191, 191);
}
.accountItem p,
.accountItem h3 {
	color: black;
}
.accountItem a {
	color: rgb(0, 119, 255);
}

.accountItem .row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.accountItem .row .left {
	width: 80%;
}
.subscribeBtn {
border: none;
background: none;
font-family: "Myriad-Pro-Regular";
}
.accountItem .row .left .textInput {
	height: 2.5rem;
	width: 100%;
	border-radius: 5px;
	border: 2px solid rgb(167, 167, 167);
	background-color: white;
	padding: 0 10px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}
.accountItem .row .left label {
	color: black;
	letter-spacing: 2px;
	line-height: 1.75rem;
	font-family: "Myriad-Pro-Regular";
	font-size: 1rem;
}

.accountItem .buttonGroup {
	padding-top: 15px;
	width: 200px;
	display: flex;
	justify-content: flex-start;
}
.accountItem .save,
.accountItem .edit,
.accountItem .cancel {
	border-radius: 0;
	font-size: 0.9rem;
	letter-spacing: 1px;
}
.accountItem .save,
.accountItem .cancel {
	height: 2.5rem;
	width: 100%;
	border-radius: 5px;
	border: 2px solid rgb(167, 167, 167);
	background-color: white;
	padding: 0 10px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}

.accountItem .edit {
	border: none;
	background-color: transparent;
	color: rgb(0, 119, 255);
}
.accountItem .edit:hover {
	text-decoration: underline;
}

.accountItem .save {
	margin-right: 10px;
}

.accountItem .save {
	background-color: rgb(0, 93, 164);
	color: white;
	border: 1px solid rgb(0, 93, 164);
}
.accountItem .cancel {
	background-color: white;
	border: 1px solid grey;
}
.accountItem .save:hover {
	background-color: rgb(0, 64, 112);
}
.accountItem .cancel:hover {
	background-color: rgb(220, 220, 220);
}

.accountItem .subscribeBtn {
	color: rgb(0, 93, 164);
}
.accountItem .subscribeBtn:hover {
	text-decoration: underline;
	cursor: pointer;
}
