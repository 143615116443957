.header {
	text-align: center;
	padding: 3rem 1rem;
}

.header h1 {
	margin-bottom: 1rem;
}

.header p {
	letter-spacing: 1px;
}

.locationSearch {
	padding: 2rem 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #50504d;
	position: relative;
	overflow: visible;
}
.filterTogglerBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 3rem;
	background-color: #f3f3f3;
	border: none;
	letter-spacing: 1px;
	position: relative;
}
.filterTogglerBtn .filtersIcon {
	margin-right: 1rem;
}

.filterTogglerBtn .closeBtn {
	position: absolute;
	right: 1rem;
}
.locationSearch .currentLocationBtn {
	display: flex;
	align-items: center;
	height: 35px;
	border-radius: 20px;
	border: none;
	background-color: white;
	padding: 0 1.5rem;
	font-family: "Erbaum-Light", sans-serif;
	color: #313131;
}
.locationSearch .currentLocationBtn svg {
	margin-right: 5px;
}
.locationSearch .inputArea {
	height: 35px;
	width: 300px;
	overflow: visible;
	z-index: 1;
}
.locationSearch .inputArea input {
	height: 35px;
	width: 300px;
	padding: 0 0.75rem;
	border-radius: 4px;
	border: none;
	color: #313131;
	font-family: "Erbaum-Light", sans-serif;
}
.locationSearch .inputArea input::placeholder {
	color: #313131;
}

.orText {
	margin: 1rem;
}

.dropdown {
	background-color: white;
	border-radius: 4px;
	border: 1px solid rgb(179, 179, 179);
	box-shadow: 0px 2px 22px -5px rgba(0, 0, 0, 0.5);
}
.dropdown .spinnerContainer {
	transform: scale(0.5);
}
.dropdown li {
	cursor: pointer;
	padding: 1rem;
	border-top: 1px solid rgb(179, 179, 179);
}
.dropdown li:hover {
	background-color: rgb(236, 236, 236);
}

.dropdown li * {
	color: black;
}
.searchBtn {
	padding: 0 2rem;
	height: 36px;
	background-color: rgba(0, 0, 0, 0.25);
	border: 2px solid rgb(0, 94, 164);
	color: white;
	border-radius: 18px;
	margin-left: 2rem;
	letter-spacing: 2px;
}

.searchBtn:hover {
	background-color: rgba(0, 0, 0, 0.5);
}
.mapLoading {
	height: 700px;
	width: 100%;
	background-color: rgb(236, 236, 236);
}

.mapArea {
	display: flex;
}

.sidebarList {
	background-color: white;
	width: 575px;
	height: 700px;
	max-height: 100%;
	overflow-y: scroll;
	z-index: 1;
	box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.75);
	padding-bottom: 5rem;
}
.sidebarList .spinnerContainer {
	margin-top: 5rem;
}

@media (max-width: 900px) {
	.mapArea {
		flex-direction: column-reverse;
	}
	.sidebarList {
		width: 100%;
		height: 500px;
	}
}
.sidebarList .sportFilterWrapper {
	padding: 2rem;
	margin: 0 1rem;
	border-bottom: 2px solid #d8d8d8;
}
.sidebarList .sportFilterWrapper .label {
	color: black;
	margin-left: 5px;
}
/* .filterBySport {
	position: relative;
	margin-top: 0.5rem;
}
.filterBySport button {
	width: 100%;
	height: 40px;
	border: 1px solid black;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.05);
}
.filterBySport .dropdownList {
	position: absolute;
	background-color: white;
	color: black;
	width: 100%;
	padding: 0.5rem 0;
	border-radius: 20px;
	margin-top: 1rem;
	box-shadow: 0px 0px 22px -9px rgba(0, 0, 0, 0.75);
	z-index: 1;
}
.filterBySport .dropdownList li {
	padding: 0.75rem 1rem;
	color: black;
	cursor: pointer;
}
.filterBySport .dropdownList li:hover {
	background-color: rgba(0, 0, 0, 0.05);
} */
.sidebarList .listItem {
	padding: 2rem 3rem;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	width: 100%;
	display: block;
}

/* .sidebarList .listItem:hover {
	background-color: #f1f1f1;
} */

.sidebarList .listItem * {
	color: black;
}
.sidebarList .listItem h6 {
	font-family: "Erbaum-Book", sans-serif;
}

.viewFacilityLink {
	display: block;
	margin-top: 1rem;
	text-decoration: none;
	z-index: 2;
}
.viewFacilityLink button {
	display: block;
	width: 100%;
	font-family: "Erbaum-Light", sans-serif;
	background-color: #d7d7d7;
	height: 40px;
	border-radius: 21px;
	border: none;
	/* border: 1px solid #979797; */
}
.viewFacilityLink button img {
	height: 13px;
	margin-right: 5px;
}
.noDataMessage {
	color: #000;
	text-align: center;
	padding: 2rem 0;
}

.marker {
	cursor: pointer;
	overflow: visible;
	position: relative;
}

.activeInfoBox,
.hoverInfoBox {
	position: absolute;
	background-color: #015da4;
	padding: 0.5rem;
	color: #fff;
	width: 150px;
	border: 1px solid #015da4;
	bottom: -30px;
	left: 0;
	transform: translate(-25%, 0);
	border-radius: 3px;
	font-family: "Erbaum-Regular";
	text-align: center;
}
.hoverInfoBox {
	display: none;
}
.marker:hover .hoverInfoBox {
	display: block;
}

@media (max-width: 800px) {
	.locationSearch {
		flex-direction: column;
		background-color: white;
	}
	.locationSearch .inputArea input {
		border: 1px solid grey;
	}
	.locationSearch .currentLocationBtn {
		background-color: #ececec;
		border: 1px solid #1da1f2;
	}
	.orText {
		color: black;
	}
	.searchBtn {
		margin-left: 0;
		margin-top: 1rem;
		border: none;
		background-color: rgb(0, 94, 164);
		color: white;
		width: 300px;
		max-width: 100%;
	}
	.filterBySport {
		width: 300px;
		max-width: 100%;
		margin-bottom: 2rem;
	}
}
