.formWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 3.5rem 2rem;
	margin-bottom: 3rem;
}

.formRow {
	display: flex;
}

.formWrapper form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.formWrapper form {
	width: 400px;
}

@media (max-width: 510px) {
	.formWrapper form {
		width: 100%;
	}
	.formWrapper {
		padding: 2.5rem 1.5rem;
	}
}

.formWrapper label {
	color: black;
	letter-spacing: 2px;
	line-height: 1.75rem;
	font-family: "Myriad-Pro-Regular";
	font-size: 1rem;
}

.inputGroup {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;

	margin: 0.5rem;
}

.inputGroup {
	width: 100%;
}

.inputGroup input {
	height: 2.5rem;
	width: 100%;
	border-radius: 5px;
	border: 2px solid rgb(167, 167, 167);
	background-color: white;
	padding: 0 10px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}

.submitBtn {
	width: 100%;

	height: 2.5rem;
	background-color: rgb(0, 93, 164);
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-top: 1rem;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}

.submitBtn:hover {
	background-color: rgb(94, 155, 201);
}

.switchToSignUpBtn {
	width: 100%;

	height: 2.5rem;
	background-color: rgba(255, 255, 255, 0);
	color: black;
	border-radius: 5px;
	font-size: 1rem;
	border: 2px solid black;
	cursor: pointer;
	margin-top: 1rem;
}
.forgotPasswordBtn {
	border: none;
	background: transparent;
	letter-spacing: 1px;
	font-size: 0.8rem;
	align-self: flex-end;
	font-family: "Myriad-Pro-Regular";
}
.forgotPasswordBtn:hover {
	text-decoration: underline;
}
.errorMessage {
	color: red;
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
}
.successMessage {
	color: green;
	letter-spacing: 1px;
	font-size: 1rem;
	font-family: "Myriad-Pro-Regular";
}

.passwordRequirementsLabel {
	margin: 10px 0 5px 0;
	width:100%;
	color: black;
	letter-spacing: 1px;
	font-size: 1rem;
	font-family: "Myriad-Pro-Regular";
	text-align: left;
}

.generalMessage {
	color: black;
	letter-spacing: 1px;
	font-size: 1rem;
	font-family: "Myriad-Pro-Regular";
	text-align: center;
}

/* Checkbox area */
.userTypeSelection {
	margin: 2rem 0;
}

.userTypeSelectionTitle {
	display: block;
	color: black;
	text-align: center;
	margin: 0 auto 1rem auto;
	letter-spacing: 2px;
	line-height: 1.75rem;
	font-family: "Myriad-Pro-Regular";
}
.userTypeErrorMessage {
	display: block;
	color: red;
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin: 0 auto;
	margin-top: 10px;
	text-align: center;
}
.checkboxGroup {
	display: flex;
}
.container {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 18px;
	cursor: pointer;
	font-size: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: 0 15px;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
}
/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 2px solid rgb(158, 158, 158);
	border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: rgba(204, 204, 204, 0.405);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	/* background-color: #ccc; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 1px;
	width: 3px;
	height: 8px;
	border: solid rgb(158, 158, 158);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* input[type="radio"]:after {
	background-color: white;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
}
.radioInput {
	width: 15px;
	height: 15px;
	border: 2px solid green;
	border-radius: 20px;
	margin: 5px;
	 min-width: 100%;
	min-height: 100%; 
}

 input[type="radio"]:checked:after {
	border-radius: 50%;
	background-color: green;
	position: relative;
}
*/
.paymentForm {
	width: 100%;
	max-width: 400px;
}
.subscriptionSelectTitle {
	color: black;
	margin-bottom: 1.5rem;
}
.subscriptionSelect {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.subscriptionSelect .row {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
}

/* .subscriptionSelect .row .radioBtnGroup:nth-child(1) {
	margin-right: 15px;
} */
.radioBtnGroup {
	display: flex;
	align-items: center;
	margin-bottom: 7px;
}
.radioInput {
	margin-right: 15px;
}

.radioBtnLabel {
	letter-spacing: 1px;
	line-height: 1rem;
	font-family: "Myriad-Pro-Regular";
	font-size: 1rem;
	text-transform: uppercase;
}
.horizontalRule {
	margin: 25px 0;
	height: 2px;
	width: 100%;
	background-color: rgb(211, 211, 211);
	border: none;
}
.paymentElement {
	width: 100%;
}
.paymentConfirmation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 550px;
}
.paymentConfirmation h3 {
	color: rgb(65, 65, 65);
	margin-bottom: 25px;
	text-align: center;
	font-family: "Erbaum-Regular";
}
.paymentConfirmation .submitBtn {
	width: 300px;
}
@media (max-width: 340px) {
	.paymentConfirmation .submitBtn {
		width: 100%;
	}
}
.successMessageSmall {
	display: block;
	color: green;
	width: 100%;
	font-family: "Myriad-Pro-Regular";
	letter-spacing: 1px;
	font-size: 0.8rem;
	text-align: center;
	margin: 5px 0;
}
.resendInfo {
	color: rgb(46, 46, 46);
	text-align: center;
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
}
.resendInfo button{
	display: inline-block;
background: none;
border: none;
color: rgb(0, 93, 164);
letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";

}

.resendInfo button:hover{
text-decoration: underline;
}
.paymentConfirmation .infoText {
	margin-top: 45px;
	color: rgb(116, 116, 116);
	text-align: center;
}

.verifyEmailPrompt {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
}

.subscriptionMessage {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
	margin-top: 5px;
	box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	-webkit-box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	-moz-box-shadow: -1px 3px 12px -3px rgba(0, 0, 0, 0.33);
	margin: 0;
	margin-top: 15px;
	padding: 10px;
	background: rgb(230, 244, 255);
}
.subscriptionMessage > span {
	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Bold";
}

.policyPrompt {
	display: flex;
	align-items: flex-start;
	margin-top: 15px;
}
.policyPrompt input {
	margin-top: 4px;
}
.policyPromptText {
	margin-left: 9px;

	color: rgb(46, 46, 46);
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-family: "Myriad-Pro-Regular";
}

.policyPrompt a {
	color: rgb(0, 93, 164);
}

.passwordChecklist {
	width: 100%;
}
.passwordChecklist span {
	color:black;
}