.modalWrapper {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
.modal {
	width: auto;
	height: auto;
	background-color: white;
	max-height: 95%;
	overflow-y: auto;
	position: relative;
}
@media (max-width: 510px) {
	.modal {
		width: 90%;
	}
}

.closeBtn {
	right: 0;
	top: 0;
	position: absolute;
	margin: 1rem;
	background-color: transparent;
	border: none;
}

.loadingOverlay {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.loadingContainer span {
	margin-top: 20px;
	color: black;
}
