.home {
	width: 100%;
	margin: 0 auto;
}

.section1 {
	padding: 35px 0;
	background: rgb(22, 23, 27);
	background: radial-gradient(circle, rgb(44, 50, 54) 0%, rgb(15, 17, 19) 100%);
}

.tagCarouselContainer {
	margin: 0 auto;
	max-width: 1200px;
	padding: 15px 50px;
	border-top: 1px solid grey;
	border-bottom: 1px solid grey;
}

.find {
	text-align: center;
	display: block;
	width: 100%;
	/* max-width: 800px; */
	margin: 0 auto;
	padding: 4rem 0.5rem 2rem 0.5rem;
	background-image: url("../../../img/Noise-bg.png");
	background-attachment: fixed;
}
.findTitle {
	display: block;

	text-transform: uppercase;

	letter-spacing: 3px;
}
.blueHorizontalRule {
	margin: 0.75rem auto;
	width: 6rem;
	height: 3px;
	background-color: rgb(62, 157, 229);
}

.findDesc {
	/* font-size: 1.25rem; */
	text-align: center;
	padding: 0 2rem;
	margin: 0 auto;
	width: 100%;
	max-width: 500px;
	line-height: 1.25;
	letter-spacing: 1.5px;
}

.findSearchButton {
	width: 100px;
	background: #808080;
	color: #fff;
	border: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.searchBar {
	margin: 1rem 0;
}
.searchBar input {
	height: 40px;
	width: 100%;
	max-width: 500px;
	border-radius: 7px 0 0 7px;
	font-size: 1rem;
	padding: 0 15px;
	border: none;
}
.searchBar button {
	height: 40px;
	width: 100px;
	border-radius: 0 7px 7px 0;
	background-color: rgb(62, 157, 229);
}
.gamesNearMeLink {
	width: 275px;
	font-family: "Myriad-Pro-Semibold";
	letter-spacing: 2px;
	color: white;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 0.5rem 0;
}
.gamesNearMeLink:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 7px;
}

.section3 {
	padding: 4rem 0;
}
.headingRow {
	display: flex;
	align-items: center;
}
.icon {
	display: inline-block;

	margin-right: 10px;
	margin-left: 1rem;
}
.icon img {
	height: 25px;
	width: 25px;
}
.expandableList {
	margin: 0 auto;
	max-width: 1200px;
	color: white;
	margin-bottom: 5rem;
}

.noDataMessage {
	display: flex;
	height: 50px;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem 15px 0 15px;
}
.noDataMessage p {
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
}
.leaguesContainer,
.sportsCategoryContainer {
	display: flex;
	margin: 0 auto;
	max-width: 1200px;
	padding-top: 2rem;
	padding-bottom: 3rem;
}
.leaguesContainerMobile {
	padding-top: 2rem;
	padding-bottom: 3rem;
}
.leaguesContainerMobile img {
	padding: 0 1rem;
}
.leaguesContainer {
	justify-content: space-between;
	padding: 2rem 15px;
}
.sportsCategoryContainer a {
	cursor: pointer;
	text-decoration: none;
}

.sportsCategory {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: calc(25% - 30px);
	padding: 1rem;
	margin: 0 15px;
}
.sportsCategory:hover {
	transform: scale(1.02);
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);

	transition: all 100ms ease;
}

.sportsCategory img {
	display: block;
	margin: 0 auto;
}

.sportsCategory p {
	text-align: center;
	margin-top: 1rem;
}
@media (max-width: 950px) {
	.sportsCategoryContainer {
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
	.leaguesContainer {
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
	.leaguesContainer img {
		margin-bottom: 1rem;
	}

	.sportsCategory {
		width: calc(50% - 30px);
		margin: 0;
		margin-bottom: 1rem;
	}
}

@media (max-width: 350px) {
	.sportsCategory {
		width: 100%;
		margin: 0 15px;
		margin-bottom: 1rem;
	}
}
