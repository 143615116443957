.footer {
	background: rgb(25,26,28);
	background: linear-gradient(135deg, #005da4 0%, #003c69 100%); 
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-direction: column;
	padding: 2rem 1.5rem;
	z-index: 4;
	box-shadow: 0px -1px 22px -3px rgba(0, 0, 0, 0.50);
}
.left {
	width: 300px;
	display: flex;
	flex-direction: column;
}
.middle {
	display: flex;
	flex-direction: column;
}

.right {
	width: 300px;
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 1024px) {
	.footer {
		margin-bottom: 50px;
	}
}

.footer strong {
	font-weight: bold;
	letter-spacing: 1px;
}
.footer a {
	text-decoration: none;
}
.footer a:hover {
	text-decoration: underline;
}

.footerLogo1Mobile {
	/* margin: 0 0 2rem 0; */
	width: 190px;

	margin-bottom: 20px;
}
.footerLogo1Desktop {
	display: none;
	/* margin: 0 0 2rem 0; */
	width: 190px;

	margin-bottom: 20px;
}
.footerLogo2 {
	width: 150px;
	margin-bottom: 30px;
}

.footerSocialBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0 0 2rem;
}
.footerSocialIcon {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 1rem 0 0;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
}
.footerSocialIcon:hover {
	background: rgb(212, 212, 212);
}

/* @media (min-width: 790px) {
	.footerLogo1 {
		margin: 0;
	}
} */

.support {
	font-size: 0.85rem;
	margin: 0;
	margin-bottom: 2rem;
}
/* .supportNumberRow {
	display: none;
	font-size: 0.85rem;
	margin: 0;
} */
.footerCopy {
	color: #fff;
	display: block;
	margin: 0;
	text-align: left;
	font-size: 0.85rem;
	white-space: nowrap;
}

.footerNav {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 2rem 0;
}

.footerNavLink {
	color: #fff;
	font-size: 0.85rem;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: left;
}
.footerNavSpacer {
	margin: 1rem 0;
}
/* @media screen and (min-width: 700px) {
	.footerNav {
		flex-direction: row;
	}
	.footerNavSpacer {
		border-right: 1px solid #000;
	
	}
} */

@media screen and (min-width: 850px) {
	.footer {
		flex-direction: row;
	}
	.footerNav {
		align-items: center;
	}

	.middle {
		align-items: center;
	}

	.right {
		align-items: flex-end;
		justify-content: space-between;
		height: 150px;
	}
	.footerLogo1Mobile {
		display: none;
	}
	.footerLogo1Desktop {
		display: block;
	}
}
@media screen and (min-width: 1400px) {
	.footerNav {
		flex-direction: row;
	}
	.footerNavLink {
		padding: 0 1rem;
	}
	/* .footerNavSpacer {
		border-right: 1px solid #000;
	} */
}
@media screen and (min-width: 1400px) {
	/* .footerNav {
		margin: 0;
	} */
	/* .footerSocialBlock {
		justify-content: flex-end;
		margin: 0;
	} */
	/* .supportNumberCol {
		display: none;
	}
	.supportNumberRow {
		display: block;
	} */
}
