.mobileMediaList {
	margin-top: 2rem;
	text-decoration: none;
}

.mobileMediaList .slick-slide {
	padding: 0 10px;
}
.itemLink {
	text-decoration: none;
}
.itemTitle {
	margin-top: 1rem;
	text-decoration: none;
}
.slick-list {
	padding: 0 15px !important;
}
.itemLocation {
	text-transform: uppercase;
	color: rgb(224, 224, 224);
	font-size: 0.75rem;
	letter-spacing: 2px;
}

.loadMoreBtnWrapper {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	margin-top: 20%;
	height: 100%;
	min-height: 100%;
	width: 100px;
}

.loadMoreBtn {
	width: auto;
	color: #fff;
	font-family: "Myriad-Pro-Regular", sans-serif;
	height: 100%;
	min-height: 100%;
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: rgba(0, 0, 0, 0.267);
}
.loadMoreBtn img {
	height: 14px;
	margin-left: 5px;
}
