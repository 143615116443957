.headingRow {
	display: flex;
	align-items: center;
}
.icon {
	display: inline-block;

	margin-right: 10px;
	margin-left: 1rem;
}
.icon img {
	height: 25px;
	width: 25px;
}

.sectionTitle {
	display: inline-block;
	color: white;
}

.noDataMessage {
	display: flex;
	height: 50px;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem 15px 0 15px;
}
.noDataMessage p {
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
}

.errMessage {
	background-color: rgba(255, 0, 0, 0.193);
	border: 1px solid rgba(255, 0, 0, 0.323);
	display: flex;
	/* height: 50px; */
	justify-content: flex-start;
	align-items: center;
	margin-top: 1rem;
	padding: 0.75rem;
	/* margin: 1rem 15px 0 15px; */
}
.errMessage p {
	text-align: left;
	color: rgb(191, 191, 191);
}
